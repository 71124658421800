import axios from 'axios'

const mutations = {
  set_data(state, payload) {
    if (payload.ok) {
      state.items = payload.items
      state.total = payload.total
      state.aggs = payload.aggs
    }
    state.loading = false
  },
  set_loading(state, payload) {
    state.loading = payload
  },
}

const actions = {
  async load_filter({commit}, payload) {
    commit('set_loading', true)
    let { data } = await axios.post('/api/v1/partners/list', payload)
    commit('set_data', data)
  },
  async set_loading({commit}, payload) {
    commit('set_loading', payload)
  },
}

const getters = {
  loading: (state) => state.loading,
  total: (state) => state.total,
  aggs: (state) => state.aggs,
  items: (state) => state.items
}

export default {
  state() {
    return {
      loading: false,
      items: [],
      total: null,
      aggs: {}
    }
  },
  getters: getters,
  mutations: mutations,
  actions: actions
}