<template>
    <div class="wrapper-filter">
        <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
            <symbol id="filter-star-half" viewBox="0 0 24 24" preserveAspectRatio="none">
                <path d="M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
            </symbol>
            <symbol id="filter-star-outline" viewBox="0 0 24 24" preserveAspectRatio="none">
                <path d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" />
            </symbol>
            <symbol id="filter-star" viewBox="0 0 24 24" preserveAspectRatio="none">
                <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
            </symbol>
            <symbol id="filter-close" viewBox="0 0 24 24" preserveAspectRatio="none">
                <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </symbol>
            <symbol id="filter-revshare" viewBox="0 0 24 24" preserveAspectRatio="none">
                <path d="M16.18,19.6L14.17,16.12C15.15,15.4 15.83,14.28 15.97,13H20C19.83,15.76 18.35,18.16 16.18,19.6M13,7.03V3C17.3,3.26 20.74,6.7 21,11H16.97C16.74,8.91 15.09,7.26 13,7.03M7,12.5C7,13.14 7.13,13.75 7.38,14.3L3.9,16.31C3.32,15.16 3,13.87 3,12.5C3,7.97 6.54,4.27 11,4V8.03C8.75,8.28 7,10.18 7,12.5M11.5,21C8.53,21 5.92,19.5 4.4,17.18L7.88,15.17C8.7,16.28 10,17 11.5,17C12.14,17 12.75,16.87 13.3,16.62L15.31,20.1C14.16,20.68 12.87,21 11.5,21Z" />
            </symbol>
            <symbol id="filter-cpa" viewBox="0 0 24 24" preserveAspectRatio="none">
                <path d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4M11,6A2,2 0 0,0 9,8A2,2 0 0,0 11,10A2,2 0 0,0 13,8A2,2 0 0,0 11,6M11,13C11.68,13 12.5,13.09 13.41,13.26L11.74,14.93L11,14.9C8.03,14.9 4.9,16.36 4.9,17V18.1H11.1L13,20H3V17C3,14.34 8.33,13 11,13Z" />
            </symbol>
            <symbol id="filter-payout" viewBox="0 0 24 24" preserveAspectRatio="none">
                <path d="M15,15V12H18V10L22,13.5L18,17V15H15M14,8.7V10H2V8.7L8,5L14,8.7M2,17H14V19H2V17M7,11H9V16H7V11M3,11H5V16H3V11M11,11H13V16H11V11Z" />
            </symbol>
            <symbol id="filter-menu-down" viewBox="0 0 24 24" preserveAspectRatio="none">
                <path d="M18,9V10.5L12,16.5L6,10.5V9H18M12,13.67L14.67,11H9.33L12,13.67Z" />            
            </symbol>
            <symbol id="filter-sync" viewBox="0 0 24 24" preserveAspectRatio="none">
                <path d="M12,18A6,6 0 0,1 6,12C6,11 6.25,10.03 6.7,9.2L5.24,7.74C4.46,8.97 4,10.43 4,12A8,8 0 0,0 12,20V23L16,19L12,15M12,4V1L8,5L12,9V6A6,6 0 0,1 18,12C18,13 17.75,13.97 17.3,14.8L18.76,16.26C19.54,15.03 20,13.57 20,12A8,8 0 0,0 12,4Z" />
            </symbol>
        </svg>        
        <div class="wrapper-control">
            <div class="control-box search-box">
                <div class="wrapper-search">
                    <input type="text" @input="throttledSearch" v-model="keyword" class="search-query" placeholder="Поиск..." />
                </div>
            </div>
            <div class="control-box">
                <div class="control-box-header" @click="toggle_filter('services')">
                    <strong>Вертикали</strong>
                    <span class="collapse" v-if="aggs.services && aggs.services.buckets.length">
                        <svg><use xlink:href="#filter-menu-down" /></svg>
                    </span>
                </div>
                <div class="control-box-options" :class="{active: collapse.services}" v-if="aggs.services && aggs.services.buckets.length">
                    <ul>
                        <li v-for="(item, i) in aggs.services.buckets" :key="i">
                            <div class="field">
                                <input class="checkbox" :id="build_id('service', i)" type="checkbox" v-model="filters[JSON.stringify({'services.keyword': item.key})]" :disabled="loading" />
                                <label :for="build_id('service', i)">{{ item.key || '-' }}</label>
                            </div>
                            <span class="count">({{ item.doc_count }})</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="control-box">
                <div class="control-box-header" @click="toggle_filter('features')">
                    <strong>Модель оплаты</strong>
                    <span class="collapse" v-if="aggs.features && aggs.features.buckets.length">
                        <svg><use xlink:href="#filter-menu-down" /></svg>
                    </span>
                </div>
                <div class="control-box-options" :class="{active: collapse.features}" v-if="aggs.features && aggs.features.buckets.length">
                    <ul>
                        <li v-for="(item, i) in aggs.features.buckets" :key="i">
                            <div class="field">
                                <input class="checkbox" :id="build_id('feature', i)" type="checkbox" v-model="filters[JSON.stringify({'features.keyword': item.key})]" :disabled="loading" />
                                <label :for="build_id('feature', i)">{{ item.key || '-' }}</label>
                            </div>
                            <span class="count">({{ item.doc_count }})</span>
                        </li>
                    </ul>
                </div>
            </div>
            <!--
            <div class="control-box">
                <div class="control-box-header">
                    <strong>Принимает трафик</strong>
                    <span class="collapse" @click="toggle_filter('markets')" v-if="aggs.markets && aggs.markets.buckets.length">
                        <svg><use xlink:href="#filter-menu-down" /></svg>
                    </span>
                </div>
                <div class="control-box-options" :class="{active: collapse.markets}" v-if="aggs.markets && aggs.markets.buckets.length">
                    <ul>
                        <li class="more" v-if="top_markets"><a @click="load_more">Показать все страны</a></li>
                        <li v-for="(item, i) in aggs.markets.buckets" :key="i">
                            <div class="field">
                                <input class="checkbox" :id="build_id('market', i)" type="checkbox" v-model="filters[JSON.stringify({'markets.keyword': item.key})]" :disabled="loading" />
                                <label :for="build_id('market', i)">{{ item.key || '-' }}</label>
                            </div>
                            <span class="count">({{ item.doc_count }})</span>
                        </li>
                    </ul>
                </div>
            </div>
            -->
            <div class="control-box">
                <div class="control-box-header" @click="toggle_filter('payments')">
                    <strong>Выплаты</strong>
                    <span class="collapse" v-if="aggs.payments && aggs.payments.buckets.length">
                        <svg><use xlink:href="#filter-menu-down" /></svg>
                    </span>
                </div>
                <div class="control-box-options" :class="{active: collapse.payments}" v-if="aggs.payments && aggs.payments.buckets.length">
                    <ul>
                        <li v-for="(item, i) in aggs.payments.buckets" :key="i">
                            <div class="field">
                                <input class="checkbox" :id="build_id('payments', i)" type="checkbox" v-model="filters[JSON.stringify({'payments.keyword': item.key})]" :disabled="loading" />
                                <label :for="build_id('payments', i)">{{ item.key || '-' }}</label>
                            </div>
                            <span class="count">({{ item.doc_count }})</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="wrapper-result">
            <div class="result-sort">{{ total || 'Ничего не найдено' }}</div>
            <div class="result-active-filters" v-if="active > 0">
                <h3>Active Filters ({{ active }})</h3>
                <a class="clear" @click="clear">Clear All</a>
            </div>
            <div class="result-active-filters-tags">
                <span v-if="item" v-for="(item, key, i) in filters" :key="i" @click="filters[key] = false">
                    {{ get_tag(key) }} 
                    <svg><use xlink:href="#filter-close" /></svg>
                </span>
            </div>
            <div class="result-items" :class="{loading: loading}">
                <div class="search-process" v-if="loading">
                    <svg><use xlink:href="#filter-sync" /></svg>
                    <p>Мы ищем лучшие предложения для вас</p>
                </div>
                <div class="result-box" :class="{sponsored: item.is_sponsored}" v-for="(item, i) in items" :key="i" v-if="!loading">
                    <div class="result-logo">
                        <a :href="item.url" v-if="item.logo">
                            <picture>
                                <source :srcset="[item.logo + '.webp']" type="image/webp" />
                                <img :src="item.logo" :alt="item.title" :title="item.title" />
                            </picture>                            
                        </a>
                    </div>
                    <div class="result-main">
                        <a :href="item.url" class="title">{{ item.title }}</a>
                        <div class="result-rating">
                            <svg v-for="(star, j) in item.stars" :key="j"><use :xlink:href="star" /></svg>
                            <span>{{ item.rating }}</span>
                        </div>
                        <div class="result-actions">
                            <a :href="item.url">Читать обзор</a>
                            <a :href="item.reflink" class="result-button" target="_blank">Перейти на сайт</a>
                        </div>
                    </div>
                    <div class="result-intro">
                        {{ item.intro }}
                    </div>
                    <div class="result-meta">
                        <div class="result-meta-data">
                            <svg class="revshare"><use xlink:href="#filter-revshare" /></svg>
                            <span class="key">Revshare</span>
                            <span class="value">{{ item.meta_revshare }}</span>
                        </div>
                        <div class="result-meta-data">
                            <svg class="cpa"><use xlink:href="#filter-cpa" /></svg>
                            <span class="key">CPA</span>
                            <span class="value">{{ item.meta_cpa }}</span>
                        </div>
                        <div class="result-meta-data">
                            <svg class="payout"><use xlink:href="#filter-payout" /></svg>
                            <span class="key">Выплата</span>
                            <span class="value">{{ item.meta_payout }}</span>
                        </div>
                    </div>
                    <div class="result-sponsored" v-if="item.is_sponsored">
                        Реклама
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import { mapGetters } from 'vuex'
import FilterStore from '../store/filter'

export default {
	name: 'PublicFilter',
	props: [
	],
	components: {
	},
	data () {
		return {
            filters: {},
            array: [],
            keyword: null,
            query: null,
            collapse: {
                features: false,
                platform: false,
                markets: false,
                services: false,
                payments: false
            },
            top_markets: true
		}
	},
	computed: {
		...mapGetters({
            loading: 'loading',
            total: 'total',
            aggs: 'aggs',
            items: 'items'
        }),
        payload() {
            return {filters: this.filters, keyword: this.query, top_markets: this.top_markets}
        },
        active() {
            return Object.values(this.filters).reduce((t, value) => t + value, 0)
        },
        throttledSearch() {
            return this.throttle(this.search_by_keyword, 500);
        },
	},
	methods: {
        load() {
			this.$store.dispatch('load_filter', this.payload)
        },
        clear() {
            this.filters = {}
        },
        // filter(key) {
        //     var ekey = JSON.stringify(key)
        //     this.filters = { [ekey] : true }
        // },
        get_tag(v) {
            var obj = JSON.parse(v)
            var k = Object.keys(obj)[0]
            var s = obj[k]
            return s
        },
        build_id(c, i) {
            return c + i
        },
        search_by_keyword() {
            this.query = this.keyword
        },
        throttle(callback, limit) {
            var wait = false;
            return function () {
                if (!wait) {
                    wait = true;
                    setTimeout(function () {
                        wait = false;
                        callback.call();
                    }, limit);
                }
            }
        },
        toggle_filter(key) {
            // this.collapse = []
            this.collapse[key] = !this.collapse[key]
        },
        load_more() {
            this.top_markets = false
        }         
	},
    created() {
        this.$store = new Vuex.Store(FilterStore)
        this.load()
    },
    watch: {
        payload: {
            handler() {
                this.load()
            },
            deep: true
        }
    }
}
</script>

<style scoped lang="scss">
@import "./../../../styles/scss/mixins";

@import 'breakpoint-sass';
@import 'breakpoint-slicer';

$slicer-breakpoints: 0 600px 800px 1050px;

.wrapper-filter {
    @include f-column($a: stretch);
    background-color: #E4EBEC;

    .wrapper-control {
        @include f-row($j: flex-start, $a: stretch);
        @include f-box($bg-color: #F2F2F2, $color: #000000, $padding: 0 0 0.5em 0, $radius: 4px);
        padding-bottom: .5em;
        padding-top: .5em;
        margin-bottom: .5em;
        overflow: visible;

        position: sticky;
        top: 89px;

        z-index: 5;

        @include to(2) {
            position: static;
            flex-direction: column;
        }

        .control-box {
            margin: .3em .7em;
            width: 25%;
            position:  relative;

            &.search-box {
                @include f-column($j: center, $a: center);
            }

            @include to(2) {
                width: calc(100% - 1.4em);
            }

            .control-box-header {
                @include f-box($bg-color: #ffffff, $color: #000000, $padding: 0, $radius: 4px);
                @include f-row($j: space-between, $a: center);
                padding: .5em .5em .5em 1em;
                cursor: pointer;

                strong {
                    font-size: 1.1em;
                    text-transform: uppercase;
                }

                .collapse {
                    @include f-row($j: flex-end, $a: center);

                    svg {
                        height: 1em;
                        width: 1.2em;

                        fill: #78909c;
                    }
                }
            }

            .control-box-options {
                background-color: #ffffff;
                margin-top: 1px;
                margin-left: .5em;
                margin-right: .5em;
                display: none;

                &.active {
                    display: block;
                    position: absolute;
                    z-index: 20;
                    left: 0;
                    right: 0;
                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
                    max-height: 300px;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0.8em;
                    max-height: 200px; 
                    overflow-y: scroll;
                    overflow-x: hidden;   

                    li {
                        @include f-row($j: space-between, $a: baseline);
                        margin-bottom: .5em;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &.more {
                            padding-bottom: .5em;

                            a {
                                color: #000000;
                                text-decoration: underline;
                                font-size: .8em;
                                cursor: pointer;
                            }
                        }

                        .field {
                            flex-grow: 2;

                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .count {
                            color: #78909c;
                            font-size: .9em;
                            letter-spacing: 1px;
                        }
                    }
                }
            }

            .wrapper-search {
                @include f-column($j: center, $a: center);
                width: 100%;

                .search-query {
                    box-sizing: border-box;
                    width: 100%;
                    padding: .5em .7em;
                    border-radius: 12px;
                    font-size: .9em;
                    outline: none;
                    box-shadow: none;
                    border: 1px solid #b0bec5;
                    font-family: inherit;
                }
            }

        }
    }

    .wrapper-result {
        flex-grow: 2;
        @include f-column($a: stretch);
        margin-top: .5em;

        .result-sort {
            @include f-box($bg-color: #263238, $color: #ffffff, $padding: .5em 1em, $radius: 4px);
            font-size: 1.1em;
            font-weight: 700;
            text-transform: uppercase;
        }

        .result-header {
            padding: 1em 0 0 0;
            @include f-row($j: flex-end);

            span {
                font-size: 2em;
                font-weight: 700;
                letter-spacing: 1px;
                flex-grow: 2;
            }
        }

        .result-active-filters {
            @include f-row($a: baseline);
            margin-top: 1em;

            @include to(2) {
                margin-top: .6em;
            }

            h3 {
                margin: 0;
                font-size: .9em;
            }

            .clear {
                font-size: .8em;
                margin-left: .7em;
                cursor: pointer;
            }
        }

        .result-active-filters-tags {
            @include f-row($a: center);
            flex-wrap: wrap;
            margin: 1em 0 0 0;

            span {
                @include f-row($a: center);
                @include f-box($bg-color: #ffffff, $color: #000000, $padding: 2px 7px, $radius: 5px);
                font-size: .9em;
                margin-right: .5em;
                margin-bottom: .5em;
                white-space: nowrap;
                cursor: pointer;

                svg {
                    margin-left: .5em;
                    fill: #e53935;
                    width: 1.3em;
                    height: 1.3em;
                }
            }
        }

        .result-items {
            margin-top: .5em;
            flex-grow: 2;

            &.loading {
                @include f-row($j: center, $a: flex-start);
                padding-top: 3em;
                padding-bottom: 5em;

                @include to(2) {
                    padding-top: 1em;
                    padding-bottom: 1em;
                }
            }

            .search-process {
                @include f-box($bg-color: #ffffff, $color: #000000, $padding: 1em, $radius: 12px);
                @include f-column($j: center, $a: center);
                margin: 1em auto;
                width: 300px;

                @include to(2) {
                    width: 100%;
                }

                svg {
                    fill: #558b2f;
                    width: 50px;
                    height: 50px;
                    margin: 0 0 .7em 0;

                    animation-name: ckw;
                    animation-duration: 2s; 
                    animation-iteration-count: infinite;
                    transform-origin: 50% 50%;
                    display: inline-block;                                       
                }

                p {
                    box-sizing: border-box;
                    margin: 0;
                    width: 100%;
                    border-top: 1px solid #cfd8dc;
                    font-size: 1.1em;
                    color: #90a4ae;
                    text-align: center;
                    padding: .5em 1em 0 1em;
                }
            }

            .result-box {
                @include f-box($bg-color: #ffffff, $color: #000000, $padding: 0, $radius: 5px);
                @include f-row($j: space-between, $a: stretch);
                margin-bottom: 1em;

                @include to(2) {
                    flex-direction: column;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &.sponsored {
                    border: 2px solid #f57c00;
                    background-color: #ffecb3;
                    position: relative;

                    .result-sponsored {
                        background-color: #f57c00;
                        color: #ffffff;
                        font-weight: 700;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        padding: .2em 1em;
                        font-size: .7em;

                        position: absolute;
                        right: 0;
                        top: 0;

                        overflow: hidden;
                    }
                }

                .result-logo {
                    @include f-row($j: center, $a: center);
                    width: 210px;
                    line-height: 0;
                    background-color: #b0bec5;

                    @include to(2) {
                        width: 100%;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .result-main {
                    padding: .5em 1em;
                    @include f-column($j: center, $a: flex-start);
                    width: calc((100% - 210px) * .35);

                    @include to(2) {
                        width: 100%;
                        justify-content: flex-start;
                        align-items: center;
                        padding-top: 1em;
                    }

                    .title {
                        font-size: 1.2em;
                        color: inherit;
                        text-decoration: none;
                        color: #455a64;
                    }

                    .result-rating {
                        @include f-row($a: center);
                        margin-top: .3em;
                        font-size: .8em;
                        line-height: 1em;

                        svg {
                            fill: #fb8c00;
                            width: 1.4em;
                            height: 1.4em;
                        }

                        span {
                            font-weight: 700;
                            font-size: 1.2em;
                            margin-left: .5em;
                            padding-top: .1em;
                            color: #4db6ac;
                        }
                    }

                    .result-actions {
                        @include f-row;
                        margin-top: .3em;

                        @include to(2) {
                            margin-top: 1em;
                            justify-content: center;
                        }

                        a {
                            color: #000000;
                            font-weight: 700;
                            margin-right: 1em;
                            font-size: 1.2em;
                            text-decoration: none;
                            white-space: nowrap;

                            @include to(2) {
                                margin-left: .5em;
                                margin-right: .5em;
                            }

                            &.result-button {
                                @include f-button($padding: .4em .9em, $font-size: .95em, $border-radius: 15px, $bg-color: #7cb342, $bg-hover-color: #388e3c, $font-color: #ffffff, $font-weight: 700);
                            }
                        }
                    }
                }

                .result-meta {
                    padding: .4em 1em;
                    @include f-row($j: center);
                    width: calc((100% - 210px) * .35);

                    @include to(2) {
                        width: 100%;
                    }

                    .result-meta-data {
                        @include f-column($j: center, $a: center);

                        font-size: .9em;
                        text-transform: uppercase; 
                        color: #757575;
                        margin: .2em 1em;

                        svg {
                            width: 2em;
                            height: 2em;
                            margin-bottom: .3em;

                            &.revshare {
                                fill: #8e24aa;
                            }

                            &.cpa {
                                fill: #f57c00;
                            }

                            &.payout {
                                fill: #388e3c;
                            }
                        }

                        span {  
                            &.key {
                                font-size: .8em;
                                color: #90a4ae;
                            }

                            &.value {
                                margin-top: 0;
                                font-size: 1.2em;
                                color: #26a69a;
                                text-transform: none;
                                white-space: nowrap;
                            }
                        }
                    }
                }

                .result-intro {
                    @include f-column($j: center, $a: center);
                    width: calc((100% - 210px) * .3);
                    padding: .6em 1em;
                    font-size: 1.1em;
                    color: #424242;
                    text-align: center;

                    @include to(2) {
                        width: 100%;
                    }
                }
            }
        }
    }
}


// Variables
$checkbox-size: 20px;
$checkbox-border: #cecece;
$checkbox-selected: #02BB72; // Primary colour

.checkbox {
  position: absolute;
  opacity: 0;
  
  // Text
  & + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: $checkbox-size;
    padding-left: $checkbox-size + 10;
    font-size: $checkbox-size - 2;
    color: #666; // Label colour
    
    // Checkbox
    &:before {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background: transparent;
      width: $checkbox-size;
      height: $checkbox-size;
      
      box-shadow: inset 0 0 0 1px $checkbox-border;
      border-radius: 4px;
      transition: 200ms ease-in-out all;
    }
    
    // Tick
    &:after {
      position: absolute;
      display: block;
      top: 5px;
      left: 8px;
      content: "";
      width: 3px;
      height: 7px;
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
      transform: rotate(45deg);
      
      transition: 200ms ease-in-out all;
    }
  }
  
  // Hover
  & + label:hover {
    color: #333;
  }
  
  // Focus
  & + label:focus {
    outline: none;
  }
  
  // Checked
  &:checked {
    & + label {
      &:before {
        background: $checkbox-selected;
        box-shadow: none;
      }
      &:after {
        border-color: white;
      }
    }
  }
  
  // Disabled
  &:disabled {
    & + label {
      &:before {
        background: #f2f2f2; // Disabled colour
        box-shadow: none;
      }
      &:after {
        border-color: transparent;
      }
    }
  }
  
  // Disabled Checked
  &:checked:disabled{
    & + label {
      &:before {
        background: #f2f2f2;
        box-shadow: none;
      }
      &:after {
        border-color: #cecece;
      }
    }
  }
}

@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
</style>
