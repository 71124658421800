import axios from 'axios'

const mutations = {
  set_data(state, payload) {
    state.is_auth = payload.ok
    state.errors = payload.errors
    state.loading = false
  },
  set_loading(state, payload) {
    state.loading = payload
  },
}

const actions = {
  async auth({commit}, payload) {
    commit('set_loading', true)
    let { data } = await axios.post('/api/v1/auth', payload)
    commit('set_data', data)
  },
  async set_loading({commit}, payload) {
    commit('set_loading', payload)
  },
}

const getters = {
  loading: (state) => state.loading,
  form: (state) => state.form,
  errors: (state) => state.errors,
  is_auth: (state) => state.is_auth
}

export default {
  state() {
    return {
      loading: false,
      form: {},
      errors: null,
      is_auth: false
    }
  },
  getters: getters,
  mutations: mutations,
  actions: actions
}