import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PublicFilter from './components/PublicFilter.vue'
import PublicAuth from './components/PublicAuth.vue'
import PublicComment from './components/PublicComment.vue'
import PublicSearch from './components/PublicSearch.vue'

Vue.use(Vuex)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

var filters = document.querySelectorAll('.public--filter')
for(let i = 0; i < filters.length; i++){
  new Vue({
    render: h => h(PublicFilter, {
      props: { ...filters[i].dataset }
    })
  }).$mount(filters[i])
}

var auth = document.querySelectorAll('.public--auth')
for(let i = 0; i < auth.length; i++){
  new Vue({
    render: h => h(PublicAuth)
  }).$mount(auth[i])
}

var comments = document.querySelectorAll('.public--comment')
for(let i = 0; i < comments.length; i++){
  new Vue({
    render: h => h(PublicComment, {
      props: { ...comments[i].dataset }
    })
  }).$mount(comments[i])
}

var search = document.querySelectorAll('.public--search')
for(let i = 0; i < search.length; i++){
  new Vue({
    render: h => h(PublicSearch)
  }).$mount(search[i])
}
