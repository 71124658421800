<template>
    <div class="wrapper-comment-placeholder">
        <div class="wrapper-comment-form" v-if="!comment_result && show_form">
            <textarea class="input" v-model="form.comment" placeholder="Ваш комментарий" :disabled="loading" rows="6"></textarea>
            <p class="error" v-if="errors.comment">Заполните поле</p>
            <input type="text" class="input name" v-model="form.author" placeholder="Ваше имя" :disabled="loading" />
            <p class="error" v-if="errors.author">Заполните поле</p>
            <button class="button" :disabled="loading" @click="comment">Отправить</button>
        </div>
        <div class="wrapper-comment-result" v-if="comment_result">
            Cпасибо за ваш комментарий!
        </div>
        <div class="wrapper-comment" v-if="!show_form">
            <button class="button" @click="show_form=true">Комментировать</button>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import { mapGetters } from 'vuex'
import CommentStore from '../store/comment'

export default {
	name: 'PublicComment',
	data () {
		return {
            show_form: false
		}
    },
	props: [
        'alias',
	],
	computed: {
		...mapGetters({
            loading: 'loading',
            form: 'form',
            errors: 'errors',
            comment_result: 'comment_result'
        }),
        payload() {
            return {resource: this.alias, payload: this.form}
        }
	},
	methods: {
        comment() {
			this.$store.dispatch('comment', this.payload)
        },
	},
    created() {
        this.$store = new Vuex.Store(CommentStore)
    }
}
</script>

<style scoped lang="scss">
@import "./../../../styles/scss/mixins";

@import 'breakpoint-sass';
@import 'breakpoint-slicer';

$slicer-breakpoints: 0 600px 800px 1050px;

.wrapper-comment-placeholder {
    margin-top: 1.5em;

    .wrapper-comment-form {
        @include f-column;
        @include f-box($bg-color: #eceff1, $padding: 1em 2em 1.5em 2em);

        @include to(1) {
            padding: 1em;
        }

        .input {
            @include f-input;

            padding: .8em 1.1em;
            margin: .5em 0 0 0;

            &.name {
                width: 300px;

                @include to(1) {
                    width: 100%;
                }
            }
        }

        p {
            &.error {
                color: #e53935;
                font-size: .8em;
                margin: 0;
                padding: .3em 0 0 1.5em;
            }
        }
    }

    .wrapper-comment-result {
        @include f-row($j: center, $a: center);
        @include f-box($bg-color: #7cb342, $padding: 1.5em 2em);
        font-size: 1.2em;
        width: 400px;
        margin: 0 auto;
    }

    .button {
        @include f-button($bg-color: #607d8b, $font-weight: 700, $font-color: #ffffff, $bg-hover-color: #37474f);
        margin: .5em 0 0 0;
    }
}

</style>
