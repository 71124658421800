import axios from 'axios'

const mutations = {
  set_data(state, payload) {
    state.result = payload.result
    state.loading = false
  },
  set_loading(state, payload) {
    state.loading = payload
  },
  clear(state) {
    state.result = []
  },
}

const actions = {
  async search({commit}, payload) {
    commit('set_loading', true)
    let { data } = await axios.post('/api/v1/resource/search', payload)
    commit('set_data', data)
  },
  async set_loading({commit}, payload) {
    commit('set_loading', payload)
  },
  async clear({commit}) {
    commit('clear')
  },
}

const getters = {
  loading: (state) => state.loading,
  result: (state) => state.result
}

export default {
  state() {
    return {
      loading: false,
      result: [],
    }
  },
  getters: getters,
  mutations: mutations,
  actions: actions
}