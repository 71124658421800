<template>
    <div class="wrapper-auth">
        <div v-if="!is_auth">
            <strong>Login</strong>
            <input type="text" class="input" v-model="form.login" placeholder="Login" :disabled="loading" />
            <strong>Password</strong>
            <input type="password" class="input" v-model="form.password" placeholder="Password" :disabled="loading" />
            <button class="button" :disabled="loading" @click="login">Login</button>
            <p>{{ errors }}</p>
        </div>
        <div v-if="is_auth">
            <p class="success">Auth success</p>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import { mapGetters } from 'vuex'
import AuthStore from '../store/auth'

export default {
	name: 'PublicAuth',
	data () {
		return {
		}
	},
	computed: {
		...mapGetters({
            loading: 'loading',
            form: 'form',
            errors: 'errors',
            is_auth: 'is_auth'
        }),
	},
	methods: {
        login() {
			this.$store.dispatch('auth', this.form)
        },
	},
    created() {
        this.$store = new Vuex.Store(AuthStore)
    }
}
</script>

<style scoped lang="scss">
@import "./../../../styles/scss/mixins";

.wrapper-auth {
    @include f-column;
    @include f-box($bg-color: #90a4ae);
    width: 400px;

    strong {
        font-size: 1.2em;
    }

    .input {
        box-sizing: border-box;
        width: 100%;
        padding: .5em .7em;
        border-radius: 12px;
        font-size: .9em;
        outline: none;
        box-shadow: none;
        border: 1px solid #b0bec5;
        font-family: inherit;
        margin: .5em 0;
    }

    .button {
        @include f-button($bg-color: #607d8b, $font-weight: 700, $font-color: #ffffff, $bg-hover-color: #37474f);
        margin: .5em 0 0 0;
    }

    p {
        margin: 1em 0 0 0;

        &.success {
            margin-bottom: 1em;
        }
    }
}
</style>
