<template>
    <div class="wrapper-box">
        <div class="wrapper-field">
            <input @input="throttledSearch($event)" type="text" class="input" :value="query" placeholder="Поиск..." />
        </div>
        <div class="wrapper-result">
            <div class="result-box" v-for="(item, j) in result" :key="j">
                <a :href="item.url">{{ item.title }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import { mapGetters } from 'vuex'
import SearchStore from '../store/search'

export default {
	name: 'PublicSearch',
	data () {
		return {
            query: null
		}
    },
	computed: {
		...mapGetters({
            result: 'result',
        }),
        throttledSearch() {
            return this.throttle(this.search, 500);
        },        
	},
	methods: {
        search() {
			this.$store.dispatch('search', {query: this.query})
        },
        toggle() {
            this.$store.dispatch('clear')
            this.query = null
        },
        throttle(callback, limit) {
            var wait = false;
            return function (event) {
                this.query = event.target.value
                if (!wait) {
                    wait = true;
                    setTimeout(function () {
                        wait = false;
                        callback.call();
                    }, limit);
                }
            }
        }        
	},
    created() {
        this.$store = new Vuex.Store(SearchStore)
    }
}
</script>

<style scoped lang="scss">
@import "./../../../styles/scss/mixins";

@import 'breakpoint-sass';
@import 'breakpoint-slicer';

$slicer-breakpoints: 0 600px 800px 1050px;

.wrapper-box {
    @include f-column($j: flex-start, $a: stretch);
    width: 100%;

    .wrapper-field {
        @include f-row($j: flex-end, $a: center);

        .input {
            @include f-input;
            padding: .5em 1em;
            width: 300px;

            @include to(2) {
                width: 100%;
            }
        }
    }

    .wrapper-result {
        @include f-row($j: flex-start, $a: stretch);
        margin-top: 1em;

        @include to(2) {
            flex-direction: column;
        }

        .result-box {
            @include f-box($bg-color: #78909c, $color: #ffffff, $radius: 6px, $padding: 0);
            @include f-column($j: flex-start, $a: stretch);

            width: 20%;
            margin: 0 .5em;

            @include to(2) {
                width: 100%;
                margin: .5em 0;
            }

            a {
                margin: .5em;
                letter-spacing: 0;
                font-size: 1.1em;
                color: #ffffff;
                text-align: left;
                text-decoration: none;
                text-transform: none;
                font-weight: 300;
            }
        }
    }
}

</style>
